html {
  position: relative;
  min-height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
}

.pageContentWrapper {
  margin-bottom: 132px;
}

.container-100vh {
  min-height: 80vh;
}

.row {
  margin: 0px;
}

/* Bootstrap */
.container-fluid {
  padding: 0;
}

.header-margin {
  margin-top: 64px;
}

/* Calendar */

.margin-calendar {
  margin: auto;
}

/* Navbar */

.navbar {
  font-size: 14px;
  font-weight: 300;
  color: #333;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px #ddd;
}

@media (min-width: 992px) {
  .navbar li {
    margin-left: 2em;
    margin-right: 2em;
  }

  .navbar-brand {
    margin-left: 2em;
    margin-right: 2em;
  }
}

.nav-link-span {
  padding: 20px 0px;
  position: relative;
}

.nav-link-span::before {
  transition: 300ms;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #fdc628;
}

.nav-link-ltr::before {
  width: 0%;
  bottom: 10px;
}

.nav-link-ltr:hover::before {
  width: 100%;
}

.nav-link {
  cursor: pointer;
}

/* Campaign List */

.campaigns-list-height {
  min-height: 95vh;
  max-height: 95vh;
  overflow: auto;
}
.campaigns-list-height::-webkit-scrollbar {
  display: none;
}

.campaign-card {
  padding: 10px;
  position: relative;
  text-align: center;
  cursor: pointer;
}

.campaign-card img {
  border-radius: 5px;
}

.campaign-list-title {
  font-weight: bold;
  font-size: 16px;
}

.bottom-left {
  position: absolute;
  bottom: 20px;
  left: 16px;
}

.top-left {
  position: absolute;
  top: 12px;
  left: 16px;
}

.bottom-left p {
  margin: 0;
}

.hidden {
  visibility: hidden;
}

/* Text Styles */
body {
  font-family: DMSans;
  color: #5c5c5c;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
}

.display {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 41px;
  color: #121212;
}

.card-insight-subtext {
  font-size: 14px;
  padding-bottom: 30px;
}

.insights-details-table td {
  padding: 5px;
}

.insightcard-firstrow {
  margin-bottom: 13px;
}

.insightcard-secondrow {
  margin-bottom: 16px;
}

a {
  color: inherit;
}

/* Shop */

@media (max-width: 575.98px) {
  #shopContainer .container {
    min-width: 100%;
  }
}

h1 {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #121212;
}

h2 {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.3px;
  color: #121212;
}

h3 {
  font-family: Georgia;
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.4px;
  color: #121212;
}

h4 {
  font-family: DMSans;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #121212;
}

label {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #121212;
}

.description {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #5c5c5c;
}

p {
  margin: 0px;
}

/* Button Styles */
button {
  background: #000000;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  height: 44px;
  padding: 11px 24px 11px 24px;
  color: #fff;
  font-size: 17px;
  line-height: 22px;
}

button:active {
  background: #4f4f4f;
}

button:disabled {
  color: #5c5c5c;
  background: #ededed;
}

.whiteButton {
  background: #ffffff;
  border-radius: 100px;
  border: 2px solid #000000;
  cursor: pointer;
  height: 44px;
  padding: 11px 24px 11px 24px;
  color: #000000;
  font-size: 17px;
  line-height: 22px;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
}

.whiteButton:active {
  color: #fff;
  background: #000;
}

.whiteButton:disabled {
  border: 2px solid #9b9b9b;
  color: #9b9b9b;
}

.outline {
  background: #ffffff;
  border: 2px solid #000000;
  color: #000000;
}

.outline:active {
  background: #000000;
  border: 2px solid #000000;
  color: #fff;
}

.outline:disabled {
  border: 2px solid #9b9b9b;
  color: #9b9b9b;
}

.coloured {
  background: #ff4150;
  color: #ffffff;
}

.coloured:active {
  background: #fc6d78;
  color: #ffffff;
}

.coloured:disabled {
  background: #fc6d78;
  color: #ac3b44;
}

.link {
  height: 100%;
  padding: 0;
  background: transparent;
  color: #a890fe;
  font-family: Georgia;
  font-style: italic;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.2px;
}

.link:active {
  background: #fff;
  color: #ba6929;
}

.link:disabled {
  background: #fff;
  color: #9b9b9b;
}

.greylink {
  height: 100%;
  padding: 0;
  background: transparent;
  color: #5c5c5c;
  font-family: Georgia;
  font-style: italic;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.2px;
}

.greylink:disabled {
  background: #fff;
  color: #9b9b9b;
}

/* Textfields */
.MuiFormLabel-root.Mui-focused {
  color: #a890fe;
}

.MuiInput-underline:after {
  border-color: #a890fe;
}

.MuiFormLabel-root.Mui-focused.Mui-error {
  color: #f44336;
}

/*
************************************************************
                    GENERAL STYLING
************************************************************
*/

.freebee-orange {
  color: #a890fe;
}

/*
*********
  LABEL
*********
*/
/* Textfields */
.MuiFormLabel-root.Mui-focused {
  color: #a890fe;
}

.MuiInput-underline:after {
  border-color: #a890fe;
}

.MuiFormLabel-root.Mui-focused.Mui-error {
  color: #f44336;
}

.MuiInput-underline.Mui-error:after {
  border-color: #f44336;
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid;
}

.createCampaignFab {
  height: 24px;
  margin-left: 12px;
  width: 24px;
  height: 24px;
  background: #a890fe;
  color: white;
}

.createCampaignFab.MuiFab-root {
  margin-left: 12px;
  width: 24px;
  height: 24px;
  background: #a890fe;
  color: white;
  min-height: 24px;
  box-shadow: none;
}

.loadingFreebee {
  color: #a890fe;
  align-content: center;
  text-align: center;
  width: 100%;
}

.loadingFreebee.MuiCircularProgress-colorPrimary {
  color: #a890fe;
}

.loadingWhite {
  color: #ffffff;
}
.loadingWhite.MuiCircularProgress-colorPrimary {
  color: #ffffff;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  color: #a890fe;
}

/*
************************************************************
                        LOGIN CSS
************************************************************
*/
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.MuiButton-root:hover {
  background-color: black;
  color: white;
}

.expertModeText {
  background: #a890fe;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  margin: 10px 0px;
  padding: 2px 7px;
  color: #ffffff;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  width: fit-content;
  height: fit-content;
}

.standardModeText {
  background: #000000;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  margin: 10px 0px;
  padding: 2px 7px;
  color: #ffffff;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  width: fit-content;
  height: fit-content;
}

.checkUploadText {
  background: #ffde22;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  margin: 10px 0px;
  padding: 2px 7px;
  color: #000000;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  width: fit-content;
  height: fit-content;
}

.selectLabel.MuiSelect-icon {
  background-image: url(assets/icons/ic_dropdown.svg);
}

.selectLabel.MuiSelect-iconOpen {
  background-image: url(assets/icons/ic_dropup.svg);
}

/* Datepicker */

.MuiPickersToolbar-toolbar {
  background: #a890fe;
}

.MuiButton-textPrimary {
  color: #a890fe;
}

.MuiButton-textPrimary:hover {
  background: none;
  color: #a890fe;
}

.MuiPickersDay-daySelected {
  background: #a890fe;
}

.MuiTypography-root {
  margin: 0px;
}

/* Radio */

.MuiRadio-colorSecondary.Mui-checked {
  color: #a890fe;
}

/* Select */

.MuiSvgIcon-root.MuiSelect-icon {
  color: #a890fe;
}

/* Checkbox */

/* Dialog */
.MuiDialogActions-root {
  padding: 48px 24px 24px 24px;
}

/* Fonts */

@font-face {
  font-family: DMSans;
  src: url(assets/fonts/dmsans/DMSans-Regular.ttf);
}
@font-face {
  font-family: DMSans;
  font-weight: bold;
  src: url(assets/fonts/dmsans/DMSans-Bold.ttf);
}
@font-face {
  font-family: DMSans;
  font-weight: 200;
  src: url(assets/fonts/dmsans/DMSans-ExtraLight.ttf);
}
@font-face {
  font-family: DMSans;
  font-weight: 300;
  src: url(assets/fonts/dmsans/DMSans-Light.ttf);
}
@font-face {
  font-family: DMSans;
  font-weight: 500;
  src: url(assets/fonts/dmsans/DMSans-Medium.ttf);
}
@font-face {
  font-family: DMSans;
  font-weight: 500;
  src: url(assets/fonts/dmsans/DMSans-Medium.ttf);
}
@font-face {
  font-family: DMSans;
  font-weight: 600;
  src: url(assets/fonts/dmsans/DMSans-SemiBold.ttf);
}

/* Statistiken Styling */
.graph_container {
  padding: 30px;
}

.tar {
  text-align: right;
}

.tac {
  text-align: center;
}

.a-button {
  background: #000000;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  height: 44px;
  padding: 11px 24px 11px 24px;
  color: #fff;
  font-size: 17px;
  line-height: 22px;
}
